
import { defineComponent } from "vue";
import UiIconInfo from "@/components/ui/icons/UiIconInfo.vue";

export default defineComponent({
  components: { UiIconInfo },

  props: {
    left: Boolean,
  },
});
