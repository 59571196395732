import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bd5d4b22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tooltip" }
const _hoisted_2 = { class: "tooltip__target" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiIconInfo = _resolveComponent("UiIconInfo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "target", {}, () => [
        _createVNode(_component_UiIconInfo)
      ], true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["tooltip__content", { left: _ctx.left }])
    }, [
      _renderSlot(_ctx.$slots, "content", {}, undefined, true)
    ], 2)
  ]))
}